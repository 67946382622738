import React, { PureComponent as Component } from 'react';
import {
  Grid,
  Icon,
  Image,
  Header,
  Segment,
  Card,
  Form
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import RPT from 'prop-types';

const contacts = [
  {
    name: 'Pavel Korčák',
    mail: 'pavel.korcak@performance4.cz',
    icon: 'PK',
    avatar: '/assets/pk.png',
    areas: [
      <>
        Audity a analýzy připravenosti pro Průmysl&nbsp;4.0, business
        intelligence, informační bezpečnost, procesní mapování, počítačoví
        agenti a IoT.
      </>
    ]
  },
  {
    name: 'Jaroslav Klabouch',
    mail: 'jaroslav.klabouch@performance4.cz',
    icon: 'JK',
    avatar: '/assets/jk.png',
    areas: ['Automatizace kontroly a robotizace.'],
    linkedin: 'https://www.linkedin.com/in/klabouch-jaroslav-5b16a136'
  },
  {
    name: 'Lukáš Komárek',
    mail: 'lukas.komarek@performance4.cz',
    icon: 'LK',
    avatar: '/assets/lk.png',
    areas: ['Big data, strojové učení a umělá inteligence.'],
    linkedin: 'https://www.linkedin.com/in/komareklukas/'
  }
];

class Contacts extends Component {
  static propTypes = {
    hideBackSection: RPT.bool
  };

  render() {
    const { hideBackSection } = this.props;
    return (
      <>
        <Segment
          vertical
          inverted
          style={{
            paddingLeft: '3%',
            paddingRight: '3%',
            paddingBottom: '3em'
          }}
        >
          <Header
            as="h3"
            content="Kontakty"
            textAlign="center"
            style={{
              fontSize: '2em',
              fontWeight: 'normal',
              marginBottom: '1em',
              marginTop: '1em'
            }}
          />

          <Header
            as="h5"
            content={
              <>
                V případě zájmu nás neváhejte kontaktovat, spojí se s Vámi
                odborníci v oboru a ve Vaší společnosti Vám pomohou nastartovat
                procesy pro Průmysl&nbsp;4.0
              </>
            }
            textAlign="center"
            style={{
              fontSize: '1.3em',
              fontWeight: 'normal',
              marginBottom: '3em',
              marginTop: '1em',
              padding: '0 20%'
            }}
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap'
            }}
          >
            {contacts.map(contact => (
              <Card
                style={{ margin: '0 1%', flexShrink: 0, marginTop: '10px' }}
              >
                <Card.Content
                  style={{ textAlign: 'center', paddingTop: '25px' }}
                >
                  <Image src={contact.avatar} size="tiny" circular />
                  <Card.Header style={{ marginTop: '20px' }}>
                    {contact.name}
                  </Card.Header>

                  <Card.Description>
                    <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                      {contact.areas.map(area => (
                        <li style={{ marginTop: 2, textAlign: 'center' }}>
                          {area}
                        </li>
                      ))}
                    </ul>
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <a href={`mailto:${contact.mail}`}>
                    <Icon name="mail" />
                    <span style={{ marginLeft: '5px' }}>{contact.mail}</span>
                  </a>
                  <br />
                  {contact.linkedin && (
                    <a href={contact.linkedin}>
                      <Icon name="linkedin" />
                      <span style={{ marginLeft: '5px' }}>
                        Odkaz na LinkedIn
                      </span>
                    </a>
                  )}
                </Card.Content>
              </Card>
            ))}
          </div>
        </Segment>
        <Segment vertical style={{ borderBottom: 0 }}>
          <Grid
            doubling
            columns={2}
            style={{ maxWidth: 1100, margin: '0 auto' }}
          >
            <Grid.Column>
              <Header
                as="h3"
                content="Údaje o společnosti"
                style={{
                  fontSize: '2em',
                  fontWeight: 'normal',
                  marginTop: '1em',
                  marginBottom: '1em'
                }}
              />
              <Grid
                doubling
                columns={2}
                style={{
                  fontSize: '1.05em',
                  lineHeight: '1.5em',
                }}
              >
                <Grid.Column>
              <strong>Performance4 s.r.o.</strong><br /><br />
              Husova 429<br />
              373 67 Borek<br />
              IČ: 08415765<br />
              DIČ: CZ08415765<br />
              RB č.ú. 1634272002/5500<br />
              C 29380 vedená u Krajského soudu v Českých Budějovicích<br />
              Datová schránka: ugpbbm6
              </Grid.Column>
              <Grid.Column>
              <strong>pobočka Čelákovice</strong><br /><br />
              Stankovského 377/38<br />
              250 88 Čelákovice<br />
              </Grid.Column>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Header
                as="h3"
                content="Napište nám"
                style={{
                  fontSize: '2em',
                  fontWeight: 'normal',
                  marginTop: '1em'
                }}
              />
              <Form
                action="https://formspree.io/prasil.honza@gmail.com"
                method="POST"
                style={{}}
              >
                <Form.Input
                  fluid
                  label="Váš email"
                  name="_replyto"
                  placeholder="Váš email"
                />
                <Form.TextArea
                  fluid
                  label="Text zprávy"
                  name="name"
                  placeholder="Zde vyplňte text zprávy"
                />
                <input type="hidden" name="_next" value="/odeslano" />
                <input type="text" name="_gotcha" style={{ display: 'none' }} />
                <input
                  type="hidden"
                  name="_subject"
                  value="Performance4 - kontaktní formulář"
                />
                <Form.Button>Odeslat</Form.Button>
              </Form>
            </Grid.Column>
          </Grid>
        </Segment>
        {!hideBackSection && (
          <Segment
            inverted
            vertical
            style={{ textAlign: 'center', margin: '1em 0 0', fontSize: '2em' }}
          >
            <Link to="/">
              Vrátit se na úvod&nbsp;&nbsp;
              <Icon name="arrow alternate circle up outline" />
            </Link>
          </Segment>
        )}
      </>
    );
  }
}

export default Contacts;
