import React, { PureComponent as Component } from 'react';
import { Segment, Header } from 'semantic-ui-react';

const styles = {
  paragraph: {
    fontSize: '1.3em',
    margin: '3% 0'
  }
};

class SpcComponent extends Component {
  static propTypes = {};

  render() {
    return (
      <Segment
        inverted
        vertical
        style={{ paddingLeft: '20%', paddingRight: '20%' }}
      >
        <Header
          as="h3"
          content="Automatizace kontroly"
          style={{
            fontSize: '2em',
            fontWeight: 'normal',
            marginBottom: '1.5em',
            marginTop: '1em'
          }}
        />
        <Header
          as="h4"
          content="Metoda SPC"
          style={{
            fontSize: '1.5em',
            fontWeight: 'normal',
            marginBottom: '1em',
            marginTop: '2em'
          }}
        />
        <p style={styles.paragraph}>
          Statistická regulace procesu (<i>Statistical Process Control</i>) je
          metodou pro sledování a regulaci procesů. Procesy jsou sledovány přes{' '}
          <strong>parametry výstupů z procesu</strong> (výrobky, znaky služby)
          nebo <strong>procesní parametry</strong> (fyzikální parametry,
          technologické parametry, bilanční parametry procesu, stavové znaky
          procesu a podobně).
        </p>
        <p style={styles.paragraph}>
          Dobře vybrané sledované parametry mohou přinášet informace o{' '}
          <strong>
            stavu procesu z hlediska jeho stability, dosahování očekávaných
            výsledků a umožňují predikovat příští chování procesu z historie dat
            na základě statistických metod
          </strong>
          . Princip získávání informací stojí na předpokladu pravidelného
          vzorkování parametrů z procesu při relativně malém výběru vzorků.
          Odebrané vzorky se sledují v čase z hlediska střední hodnoty a rozpětí
          jejich hodnot. Statistická metoda je založena na základních
          předpokladech náhodného vzorkování, které získává z malých výběrů
          charakteristiky vlastností všech výsledků procesu. Výsledky v čase lze
          jednoduše kategorizovat a interpretovat je jak z hlediska historie,
          tak z hlediska predikce dalšího chování.
        </p>
        <p style={styles.paragraph}>
          Základy statistické regulace procesu formou regulačních diagramů
          najdete v normě{' '}
          <i>
            ISO 7870-2 Regulační diagramy - Část 2: Shewhartovy regulační
            diagramy
          </i>
          .
        </p>
        <p style={styles.paragraph}>
          Metoda <strong>SPC</strong> představuje základ sběru dat. Dosavadní
          výběr sledovaných parametrů byl vždy založen na expertních znalostech
          (mínění odborníků a zkušenostech). Dnes se znalosti o vzájemné
          závislosti dat nebo jejich korelaci dají získat i{' '}
          <strong>bez zkušeností</strong>:
          <ul style={{ textAlign: 'left' }}>
            <li>
              extenzivně, kdy se prohledávají vzájemné vztahy parametrů nebo
            </li>
            <li>intenzivně, tak že si pokládáme správné otázky</li>
          </ul>
          <br />
          Hledání znalostí vyžaduje čas a energii, kterou lze ušetřit při sběru
          a analýze dat. Sběr dat je nutné maximálně automatizovat nebo vést
          záznamy bez ručních záznamů a zbytečných přepisů. Je podporován
          <strong>internet věcí</strong>. Sledované znaky lze uspořádat do
          vzájemné hierarchie podle sestav výrobků a znaků nebo podle
          podprocesů. Výsledky lze zpracovávat v libovolné úrovni a procesní
          fázi (<i>Product LifeCycle Management</i>
          ).
        </p>
        <p style={styles.paragraph}>
          Otevřenost dat umožňuje sdílení výsledků i s jinými aplikacemi.
          Automatizace analýzy dat je založena na softwarovém vyhodnocování
          průběhů dat na regulačních kartách a přímé eskalaci abnormality na
          odpovědné osoby. Tzn. jsou aplikovány principy štíhlé výroby včetně
          aplikace záchranné brzdy.
        </p>
      </Segment>
    );
  }
}

export default SpcComponent;
