import { Element, scroller, Events } from 'react-scroll';
import { Route, Switch, withRouter } from 'react-router-dom';
import Contacts from './containers/Contacts.react';
import Helmet from 'react-helmet';
import HomeLayout from './layouts/HomePage.react';
import ContentLayout from './layouts/Content.react';
import Offer from './containers/Offer.react';
import Spc from './containers/Spc.react';
import React, { Component } from 'react';
import routesList from './routesList';
import RPT from 'prop-types';
import Strategy from './containers/Strategy.react';
import './semantic/dist/semantic.css';
import Robotization from './containers/Robotization.react';

const titles = {
  '/kontakty': 'Kontakty',
  '/': 'Systémová integrace nástrojů Průmysl 4.0',
  '/hodnoty-a-strategie': 'Hodnoty a strategie',
  '/nabizene-sluzby': 'Nabízené služby',
  '/automatizace-kontroly': 'Statistická regulace procesu',
  '/robotizace': 'Robotizace'
};

const homepagePaths = [
  routesList.contacts,
  routesList.offer,
  routesList.strategy
];

function getClassName(path) {
  return path.replace('/', '');
}

const isServer = !(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

class ScrollRoute extends Route {
  static propTypes = {
    location: RPT.object.isRequired
  };

  state = {
    scrollName: ''
  };

  componentWillMount() {
    const { path } = this.props;
    this.setState({ scrollName: getClassName(path) });
  }

  render() {
    const { scrollName } = this.state;
    const { component: Component, path } = this.props;

    if (isServer) {
      return (
        <div>
          <Route
            path={path}
            exact
            render={() => (
              <Helmet>
                <title>{titles[path]}</title>
              </Helmet>
            )}
          />
          <Component />
        </div>
      );
    }

    return (
      <Element name={scrollName}>
        <Route
          path={path}
          exact
          render={() => (
            <Helmet>
              <title>{titles[path]}</title>
            </Helmet>
          )}
        />
        <Component />
      </Element>
    );
  }
}

class App extends Component {
  state = {
    disableScrollHandling: false
  };

  togglScrollHandling() {
    this.setState(prevState => ({
      disableScrollHandling: !prevState.disableScrollHandling
    }));
  }

  componentDidMount() {
    const { location } = this.props;

    window.addEventListener('scroll', this.onScroll);
    Events.scrollEvent.register('begin', () => {
      this.togglScrollHandling();
    });
    Events.scrollEvent.register('end', () => {
      this.togglScrollHandling();
    });

    scroller.scrollTo(getClassName(location.pathname) || 'homepage', {
      duration: 0,
      delay: 0,
      smooth: false
    });
  }

  onScroll = () => {
    if (this.state.disableScrollHandling) return;
    const scrollY = window.scrollY;

    if (homepagePaths.includes(this.props.location.pathname) && scrollY < 50) {
      this.props.history.push({ pathname: routesList.base });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    const { location } = this.props;

    scroller.scrollTo(getClassName(location.pathname) || 'homepage', {
      duration: 1500,
      delay: 100,
      smooth: true
    });
  }

  render() {
    const { location } = this.props;

    return (
      <Switch>
        <Route
          path={[routesList.base, ...homepagePaths]}
          exact
          render={() => (
            <HomeLayout currentPathname={location.pathname}>
              <ScrollRoute path={routesList.base} component={() => <div />} />
              <ScrollRoute path={routesList.strategy} component={Strategy} />
              <ScrollRoute path={routesList.offer} component={Offer} />
              <ScrollRoute path={routesList.contacts} component={Contacts} />
            </HomeLayout>
          )}
        />
        <Route
          path={[routesList.spc, routesList.robotization]}
          exact
          render={() => (
            <ContentLayout currentPathname={location.pathname}>
              <Switch>
                <ScrollRoute
                  path={routesList.robotization}
                  component={Robotization}
                />
                <ScrollRoute path={routesList.spc} component={Spc} />
              </Switch>
            </ContentLayout>
          )}
        />
      </Switch>
    );
  }
}

export default withRouter(App);
