import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Container,
  Menu,
  Segment,
  Responsive,
  Sidebar,
  Icon
} from 'semantic-ui-react';
import Heading from '../components/Heading';
import { Link } from 'react-router-dom';
import routesList from '../routesList';
import { Element } from 'react-scroll';
import Helmet from 'react-helmet';

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  render() {
    const { currentPathname, children } = this.props;

    return (
      <Responsive minWidth={Responsive.onlyTablet.minWidth}>
        <Element name="homepage">
          <Segment
            inverted
            textAlign="center"
            style={{
              minHeight: 700,
              backgroundImage:
                'linear-gradient(rgba(20, 20, 20, 0.9) 5%, rgba(0, 0, 0, 0)), url(/assets/background.jpeg)',
              backgronundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              padding: '0.5em 0em',
              height: '100vh'
            }}
            vertical
          >
            <Menu
              inverted={true}
              pointing={true}
              secondary={true}
              style={{
                border: 0,
                color: 'black'
              }}
              size="large"
              activeIndex={[
                routesList.strategy,
                routesList.offer,
                routesList.contacts
              ].indexOf(this.props.currentPathname)}
            >
              <Container>
                <Menu.Item
                  position="right"
                  as={Link}
                  to={routesList.strategy}
                  active={routesList.strategy === currentPathname}
                >
                  Hodnoty a strategie
                </Menu.Item>
                <Menu.Item
                  as={Link}
                  to={routesList.offer}
                  active={routesList.offer === currentPathname}
                >
                  Nabízené služby
                </Menu.Item>
                <Menu.Item
                  as={Link}
                  to={routesList.contacts}
                  active={routesList.contacts === currentPathname}
                >
                  Kontakty
                </Menu.Item>
              </Container>
            </Menu>

            <Heading />
          </Segment>

          {children}
        </Element>
      </Responsive>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node
};

class MobileContainer extends Component {
  state = {};

  handlePusherClick = () => {
    const { sidebarOpened } = this.state;

    if (sidebarOpened) this.setState({ sidebarOpened: false });
  };

  handleToggle = () =>
    this.setState({ sidebarOpened: !this.state.sidebarOpened });

  render() {
    const { currentPathname, children } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
        <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            animation="uncover"
            inverted
            vertical
            visible={sidebarOpened}
          >
            <Menu.Item
              position="right"
              as={Link}
              to={routesList.strategy}
              active={routesList.strategy === currentPathname}
            >
              Hodnoty a strategie
            </Menu.Item>
            <Menu.Item
              as={Link}
              to={routesList.offer}
              active={routesList.offer === currentPathname}
            >
              Nabízené služby
            </Menu.Item>
            <Menu.Item
              as={Link}
              to={routesList.contacts}
              active={routesList.contacts === currentPathname}
            >
              Kontakty
            </Menu.Item>
          </Sidebar>

          <Sidebar.Pusher
            dimmed={sidebarOpened}
            onClick={this.handlePusherClick}
            style={{ minHeight: '100vh' }}
          >
            <Element name="homepage">
              <Segment
                inverted
                textAlign="center"
                style={{
                  minHeight: 700,
                  backgroundImage:
                    'linear-gradient(rgba(20, 20, 20, 0.9) 5%, rgba(0, 0, 0, 0)), url(/assets/background.jpeg)',
                  backgronundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  padding: '0.5em 0em',
                  height: '100vh'
                }}
                vertical
              >
                <Menu inverted pointing secondary size="large">
                  <Menu.Item onClick={this.handleToggle}>
                    <Icon name="sidebar" />
                  </Menu.Item>
                </Menu>

                <Heading mobile />
              </Segment>

              {children}
            </Element>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Responsive>
    );
  }
}

const ResponsiveContainer = ({ children, currentPathname }) => {
  const isServer = !(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  );

  return (
    <div>
      <Helmet titleTemplate="%s | Performance4.cz" />
      {isServer && <div id="ssr">{children}</div>}
      {!isServer && (
        <div id="csr">
          <DesktopContainer currentPathname={currentPathname}>
            {children}
          </DesktopContainer>
          <MobileContainer currentPathname={currentPathname}>
            {children}
          </MobileContainer>
        </div>
      )}
    </div>
  );
};

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
  currentPathname: PropTypes.string
};

export default ResponsiveContainer;
