import React, { PureComponent as Component } from 'react';
import { Header, Segment, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import routesList from '../routesList';

const gridContent = [
  [
    'Procesní mapování',
    'Business intelligence',
    'Informační bezpečnost',
    '	Audity a analýzy připravenosti'
  ],
  [
    'Podpora dotací a financování',
    ['Automatizace kontroly', routesList.spc],
    ['Robotizace', routesList.robotization],
    'Počítačoví agenti'
  ],
  ['IoT', 'Big data', 'Strojové učení', 'Umělá inteligence']
];

class Offer extends Component {
  static propTypes = {};

  render() {
    return (
      <Segment
        vertical
        style={{ paddingLeft: '20%', paddingRight: '20%', marginBottom: '2em' }}
      >
        <Header
          as="h3"
          content="Naše služby"
          textAlign="center"
          style={{
            fontSize: '2em',
            fontWeight: 'normal',
            marginBottom: 0,
            marginTop: '1em'
          }}
        />

        <Header
          as="h5"
          content="V rámci naší nabídky můžete nalézt služby, které pomohou Vaší společnosti s podporou Průmysl&nbsp;4.0&nbsp;-&nbsp;jak po stránce procesní, tak z hlediska IT."
          textAlign="center"
          style={{
            fontSize: '1.3em',
            fontWeight: 'normal',
            marginBottom: 0,
            marginTop: '1em'
          }}
        />
        <Grid
          celled="internally"
          columns="equal"
          stackable
          style={{ marginTop: 40, marginBottom: 40 }}
        >
          {gridContent.map(row => (
            <Grid.Row textAlign="center">
              {row.map(col => (
                <Grid.Column
                  style={{
                    paddingTop: '1.5em',
                    paddingBottom: '1.5em',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {Array.isArray(col) ? <Link to={col[1]}>{col[0]}</Link> : col}
                </Grid.Column>
              ))}
            </Grid.Row>
          ))}
        </Grid>
      </Segment>
    );
  }
}

export default Offer;
