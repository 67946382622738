import RPT from 'prop-types'
import React, { PureComponent as Component } from 'react'
import {
  Button,
  Container,
  Header,
  Icon,
} from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'

const logo = <img
  alt="performance4 logo"
  src="/assets/logo-new.png"
/>

const styles = {
  container: {
    borderRadius: 10,
    width: 400,
    padding: '20px 0',
    background: 'linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.85))',
    transform: 'translateY(40%)',
  },
}

class NoRouterHeading extends Component {
  static propTypes = {
    mobile: RPT.bool,
  }

  render() {
    const { mobile } = this.props

    return (
      <Container text fluid={true} style={styles.container}>
        <Header
          as='span'
          content={logo}
          inverted
          style={{
            fontSize: mobile ? '2em' : '4em',
            fontWeight: 'normal',
            marginBottom: 0,
            marginTop: mobile ? '0.5em' : '3em',
          }}
        />
        <Header
          as='h2'
          content={<span>Systémová integrace<br />nástrojů Průmysl&nbsp;4.0</span>}
          inverted
          style={{
            fontSize: mobile ? '1.5em' : '1.7em',
            fontWeight: 'normal',
            marginTop: mobile ? '0.5em' : '1.5em',
          }}
        />
        <Button primary size='huge' onClick={() => this.props.history.push('/hodnoty-a-strategie')}>
          Více informací
          <Icon name='right arrow' />
        </Button>
      </Container>
    )
  }
}

export const Heading = withRouter(NoRouterHeading)
