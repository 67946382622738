import React, { PureComponent as Component } from 'react'
import { Segment, Header, Divider } from 'semantic-ui-react'

const styles = {
  paragraph: {
    fontSize: '1.3em',
    margin: '3% 0',
    textAlign: 'center'
  },
}

class Strategy extends Component {
  static propTypes = {

  }

  render() {
    return (<Segment
      inverted
      vertical
      style={{ paddingLeft: '20%', paddingRight: '20%' }}
    >
      <Header
        as='h3'
        content="Naše hodnoty a strategie"
        inverted
        textAlign="center"
        style={{
          fontSize: '2em',
          fontWeight: 'normal',
          marginBottom: '1.5em',
          marginTop: '1em',
        }}
      />
      <p style={styles.paragraph}>
        Jsme firma, která pomáhá zákazníkům s implementací nástrojů Průmyslu&nbsp;4.0.
      </p>
      <Divider />
      <p style={styles.paragraph}>
        Úzce spolupracujeme s dalšími dodavateli produktů a nástrojů.
      </p>
      <Divider />
      <p style={styles.paragraph}>
        Implementace opíráme o více nástrojů, které přináší synergické efekty ve zvyšování produktivity a efektivity.
      </p>
      <Divider />
      <p style={styles.paragraph}>
        Implementaci Průmyslu&nbsp;4.0 chápeme jako evoluci, nikoliv revoluci.
      </p>
      <Divider />
      <p style={styles.paragraph}>
        Zákazníci jsou pro nás jak výrobní podniky, tak organizace hledající zefektivnění svých administrativních činností.
      </p>
      <Divider />
      <p style={styles.paragraph}>
        Měřítkem našich výsledků je růst produktivity klienta.
      </p>
    </Segment>)
  }
}

export default Strategy
