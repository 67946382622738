const routesList = {
  base: '/',
  contacts: '/kontakty',
  strategy: '/hodnoty-a-strategie',
  offer: '/nabizene-sluzby',
  spc: '/automatizace-kontroly',
  robotization: '/robotizace'
};

export default routesList;
