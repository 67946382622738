import React, { PureComponent as Component } from 'react';
import { Segment, Header } from 'semantic-ui-react';

const styles = {
  paragraph: {
    fontSize: '1.3em',
    margin: '3% 0'
  }
};

class Robotization extends Component {
  static propTypes = {};

  render() {
    return (
      <Segment
        inverted
        vertical
        style={{ paddingLeft: '20%', paddingRight: '20%' }}
      >
        <Header
          as="h3"
          content="Robotizace"
          style={{
            fontSize: '2em',
            fontWeight: 'normal',
            marginBottom: '1.5em',
            marginTop: '1em'
          }}
        />
        <p style={styles.paragraph}>
          Naše společnost se stala od září 2019 autorizovaným dealerem korobotů
          AUBO.
        </p>
        <Header
          as="h4"
          content="O společnosti Auto Robotics"
          style={{
            fontSize: '1.5em',
            fontWeight: 'normal',
            marginBottom: '1em',
            marginTop: '2em'
          }}
        />
        <p style={styles.paragraph}>
          Kolaborativní roboty <strong>AUBO Robotics - Cobot</strong> jsou
          šestiosé, průmyslové roboty s nosností 3, 5, 7 a 10 kg a s dosahem od
          625 do 1350 mm. Jedná se o lehké roboty vyrobené pro agilní výrobu
          zaměřenou na člověka (<i>Human Centric</i>
          ), které mohou úzce spolupracovat v lidském pracovním prostoru v
          závislosti na hodnocení rizik bez dalšího bezpečnostního vybavení.
        </p>
        <p style={styles.paragraph}>
          <strong>Cobot AUBO</strong> je uživatelsky přívětivý, flexibilní,
          levný a velmi snadno se přesouvá, znovu nasazuje a pro provoz může být
          zapojen do běžné zásuvky  230 V.
        </p>
        <Header
          as="h5"
          content="Jednoduchost obsluhy"
          style={{
            fontSize: '1.5em',
            fontWeight: 'normal',
            marginBottom: '1em',
            marginTop: '2em'
          }}
        />
        <p style={styles.paragraph}>
          K nastavení pomocí ručního navádění robota na požadované pozice bez
          rozdílu jedná-li se o jednoduché aplikace, nebo náročné operace nejsou
          zapotřebí žádné programovací dovednosti.
        </p>
        <p style={styles.paragraph}>
          Robot AUBO mají malou základnu, což má obrovský přínos pro výrobní
          náklady díky minimálním požadavkům na podlahovou plochu. AUBO Robotics
          pomáhá společnostem získat konkurenční výhodu ve výrobním prostředí a
          zároveň omezit nebezpečné a opakující se úkoly prováděné zaměstnanci.
          Roboty AUBO se staly nepostradatelným a hlavně dostupnými pomocníky ve
          stovkách průmyslových firem na celém světě.
        </p>
        <Header
          as="h4"
          content="Produkty"
          style={{
            fontSize: '1.5em',
            fontWeight: 'normal',
            marginBottom: '1em',
            marginTop: '2em'
          }}
        />
        <p style={styles.paragraph}>
          <strong>AUBO Robotics</strong> vyrábí řadu lehkých inteligentních
          spolupracujících robotů se šesti stupni volnosti.{' '}
          <strong>Cobot</strong> byl od začátku speciálně navržen s důležitými
          funkcemi, které kombinují nejmodernější technologii s uživatelskou
          přívětivostí, aby se z něj stal robot pro spolupráci pro jakoukoli
          aplikaci. Robotická automatizace již není mimo dosah malých a
          středních společností. Všechny modely mají certifikované
          bezpečnostními funkce, konstrukce s s možností omezení výkonu a síly a
          s ručním pohonem. Certifikace zahrnují:
          <ul>
            <li>ISO 10218-1: 2011</li>
            <li>EN 60204-1: 2006 + a1: 2009</li>
            <li>ISO 12100: 2010</li>
            <li>ISO 13849-1: 2008</li>
            <li>CE, TUV, KCS a NRTAC</li>
          </ul>
          Uživatelsky přívětivá obsluha a jednoduché programování umožňuje{' '}
          <strong>rychlou návratnost investic</strong> v reálných produkčních
          prostředích, takže zaměstnanci bez programovacích dovedností mohou
          přizpůsobit tohoto robota pro většinu aplikací. Ruční vedení umožňuje
          operátorovi učit / programovat body robotů ručně nebo pomocí ovládací
          jednotky a učícího modu, oba nevyžadují žádné programovací dovednosti.
        </p>
      </Segment>
    );
  }
}

export default Robotization;
